import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { canActivate, redirectUnauthorizedTo, redirectLoggedInTo } from '@angular/fire/auth-guard';


const redirectUnauthorizedToLogin = () =>redirectUnauthorizedTo(['/']);
// Automatically log in users
const redirectLoggedInToHome = () => redirectLoggedInTo(['/tabs']);

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule),
    ...canActivate(redirectLoggedInToHome)
  },
  {
    path: 'filtercompany',
    loadChildren: () => import('./filtercompany/filtercompany.module').then( m => m.FiltercompanyPageModule)
  },
  {
    path: 'companydetails',
    loadChildren: () => import('./companydetails/companydetails.module').then( m => m.CompanydetailsPageModule)
  },
  {
    path: 'forgotpassword',
    loadChildren: () => import('./forgotpassword/forgotpassword.module').then( m => m.ForgotpasswordPageModule)
  },
  {
    path: 'newproject',
    loadChildren: () => import('./newproject/newproject.module').then( m => m.NewprojectPageModule)
  },
  {
    path: 'editprofile',
    loadChildren: () => import('./editprofile/editprofile.module').then( m => m.EditprofilePageModule)
  },
  {
    path: 'sharing',
    loadChildren: () => import('./sharing/sharing.module').then( m => m.SharingPageModule)
  },
  {
    path: 'language',
    loadChildren: () => import('./language/language.module').then( m => m.LanguagePageModule)
  },
  {
    path: 'aboutus',
    loadChildren: () => import('./aboutus/aboutus.module').then( m => m.AboutusPageModule)
  },
  {
    path: 'set-location',
    loadChildren: () => import('./set-location/set-location.module').then( m => m.SetLocationPageModule)
  },
  {
    path: 'project-jobdetails',
    loadChildren: () => import('./project-jobdetails/project-jobdetails.module').then( m => m.ProjectJobdetailsPageModule)
  },
  {
    path: 'newsdetail',
    loadChildren: () => import('./newsdetail/newsdetail.module').then( m => m.NewsdetailPageModule)
  },
  {
    path: 'rate',
    loadChildren: () => import('./rate/rate.module').then( m => m.RatePageModule)
  }

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
