// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyAkIkqEm-e5CI2hoOy8IoT8Q-RJy3dQ5Fg',
    authDomain: 'fagfolk-4f6b4.firebaseapp.com',
    databaseURL:
      'https://fagfolk-4f6b4-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'fagfolk-4f6b4',
    storageBucket: 'fagfolk-4f6b4.appspot.com',
    messagingSenderId: '864499675114',
    appId: '1:864499675114:web:5cc842062df9bf78480c93',
    measurementId: 'G-6CFK9P62YR',
  },

  /* for push notification service visite- onesignal.com */
  onesignal: {
    User: {
      appId: '63c63a48-a522-43c5-8364-e7bdb428408a',
      googleProjectNumber: '864499675114',
      restKey: 'YzQ3NmUyZDUtNjA5Yy00MTRmLWFiMDQtNzZjMGFmZDJmYWU1',
    },

    Worker: {
      appId: 'c8d36c73-1446-4b84-9cd4-6deef5d90d8d',
      googleProjectNumber: '864499675114',
      restKey: 'ODU0YWIxYmYtNDdmMC00ZDE0LTkzNTgtNDJlMDI5NjhiZmYz',
    },
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
