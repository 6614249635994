import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ToastController } from '@ionic/angular';
import { LoadingController } from '@ionic/angular';
import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NativeGeocoder, NativeGeocoderResult, NativeGeocoderOptions } from '@ionic-native/native-geocoder/ngx';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  loading:any;
  isLoading = false;
dummy;
  constructor(
    private toastCtrl: ToastController,
    public loadingController: LoadingController,
    private adb: AngularFirestore,
    private photoViewer: PhotoViewer,
    private geolocation: Geolocation,
    private nativeGeocoder: NativeGeocoder,
    private http: HttpClient,
    private callNumber: CallNumber

  ) { }



  async showToast(msg, colors, positon) {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 3000,
      color: colors,
      position: positon
    });
    toast.present();
  }


  // async presentLoading(msg?) {
  //   const loading = await this.loadingController.create({
  //     message: msg,
  //     mode:'md'
  //   });
  //   return loading.present();
  // }

  async presentLoading(message?) {
    this.isLoading = true;
    return await this.loadingController
      .create({
        message: message,
        mode:'md'
      })
      .then((a) => {
        a.present().then(() => {
          console.log('presented');
          if (!this.isLoading) {
            a.dismiss().then(() => console.log('abort presenting'));
          }
        });
      });
  }

  async dismissLoading(){
    this.isLoading = false;
    return await this.loadingController.dismiss().then(() => console.log('dismissed'));
  }

  base64ToImage(dataURI) {
    const fileDate = dataURI.split(',');
    // const mime = fileDate[0].match(/:(.*?);/)[1];
    const byteString = atob(fileDate[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([arrayBuffer], { type: 'image/png' });
    return blob;
  }


  makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }


  firebaseref(refpath){
    return this.adb.doc(refpath).ref;
   }


  viewimage(url){
    return this.photoViewer.show(url);

  }

  //get current pos
  getgeolocation(){
    return this.geolocation.getCurrentPosition();
  }

  //get location address

  getlocationaddress(lat,lng):Promise<any>{
    
    let options: NativeGeocoderOptions = {
      useLocale: true,
      maxResults: 5
   };

   return new Promise<any>((resolve, reject) => {
    this.nativeGeocoder.reverseGeocode(lat,lng,options)
    .then((result: NativeGeocoderResult[]) => {
      let address = (result[0].areasOfInterest[0]+',')+(result[0].thoroughfare).toString()+','+result[0].locality+','+result[0].subAdministrativeArea;
      resolve(address);
    })
    .catch((error: any) => {
      reject(error);
    });
   });


  }

   //Send push to user
   Sendpushtouser(title?,msg?,sid?){
    const body = {
      app_id: environment.onesignal.User.appId,
      include_player_ids: [sid],
      headings: { en: title },
      contents: { en: msg },
      data: { task: msg }
    };

    const header = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', `Basic ${environment.onesignal.User.restKey}`)
    };

    return this.http.post('https://onesignal.com/api/v1/notifications', body, header).subscribe();

  }

  //Send push to worker
  Sendpushtoworker(title?,msg?,sid?){

    const body = {
      app_id: environment.onesignal.Worker.appId,
      include_player_ids: [sid],
      headings: { en: title },
      contents: { en: msg },
      data: { task: msg }
    };

    const header = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', `Basic ${environment.onesignal.Worker.restKey}`)
    };

    return this.http.post('https://onesignal.com/api/v1/notifications', body, header).subscribe();

  }

  //call number

  callnumber(number){
      window.open(`tel:${number}`, '_system')
  }

}
