import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import {
  AngularFireStorage,
  AngularFireUploadTask,
} from '@angular/fire/storage';

import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private fireAuth: AngularFireAuth,
    private adb: AngularFirestore,
    private http: HttpClient,
    private storeage: AngularFireStorage
  ) {}

  checkAuth() {
    this.fireAuth.onAuthStateChanged((user) => {
      console.log('Changed', user);
      if(user)localStorage.setItem('Uid', user.uid);
    });
  }

  public SnapShots(collection, doc) {
    if (doc == null) {
      return this.adb.collection(collection).valueChanges();
    } else {
      return this.adb.collection(collection).doc(doc).valueChanges();
    }
  }

  public signIn({ email, password }) {
    return new Promise<any>((resolve, reject) => {
      this.fireAuth
        .signInWithEmailAndPassword(email, password)
        .then((res) => {
          console.log(res.user.uid);
          resolve(res.user);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public signUp(email: string, password: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.fireAuth
        .createUserWithEmailAndPassword(email, password)
        .then((res) => {
          console.log(res.user.uid);
          resolve(res.user);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  //get about us
  public getAppContent(contentType): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection('AppContent')
        .doc(contentType)
        // .doc('AboutUs')
        .valueChanges()
        .subscribe(
          (content: any) => {
            resolve(content);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public signOut() {
    return this.fireAuth.signOut();
    localStorage.clear();
  }

  public createProfile(uid, userprofile): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection('Users')
        .doc(uid)
        .set(userprofile)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getUserProfile(id) {
    return this.adb.collection('Users').doc(id).valueChanges();
  }

  public updateUserProfile(uid, details): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection('Users')
        .doc(uid)
        .update(details)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public updateProfilePic(uid, details) {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection('Users')
        .doc(uid)
        .update(details)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  //GetProfessions
  public GetProfessions() {
    return this.adb.collection('Professions').valueChanges();
  }

  //SendProject
  public SendProject(docid, details): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection('Projects')
        .doc(docid)
        .set(details)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  //get User Projects
  public getProjects(uid) {
    return this.adb
      .collection('Projects', (ref) =>
        ref.where('user', '==', uid).orderBy('orderBy', 'desc')
      )
      .valueChanges();
  }

  //get Project Job details
  public getprojectjobDetails(id): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      this.adb
        .collection('Projects')
        .doc(id)
        .get()
        .subscribe(
          async (details: any) => {
            let data = await details.data();
            if (data.belongWref) {
              await data.belongWref.get().then(function (doc) {
                data.belongWref = doc.data();
              });
            }
            await data.userref.get().then(function (doc) {
              data.userref = doc.data();
            });
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  //cancel project
  public cancelproject(id, cancel) {
    return this.adb.collection('Projects').doc(id).update(cancel);
  }

  //update user fcm token
  public updateuserfcm(uid, fcm): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection('Users')
        .doc(uid)
        .update(fcm)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  updateRejectWorker(workerref): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .doc(workerref.companyref)
        .get()
        .subscribe(
          (doc) => {
            let companyDetails: any = doc.data();
            console.log('workerDetails', companyDetails);
            this.updatecompanyDetails(companyDetails.id, {
              clips: companyDetails.clips + 6,
            });
            resolve(true);
          },
          (error) => {
            console.log('getCompanyDetails error', error);
            reject(false);
          }
        );
    });
  }

  //update company details
  updatecompanyDetails(id, data) {
    this.adb.collection('Company').doc(id).update(data);
  }

  //update project
  public updateProject(id, data) {
    return this.adb.collection('Projects').doc(id).update(data);
  }

  //update Interests
  public updateInterests(id, data) {
    return this.adb.collection('Interests').doc(id).update(data);
  }

  // get all companies
  public getcompanies(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection('Company')
        .get()
        .subscribe(
          async (company) => {
            let data = await company.docs.map((element) => {
              let item = element.data();
              return item;
            });
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  // get single companies
  public getsingleCompany(id): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      this.adb
        .collection('Company')
        .doc(id)
        .get()
        .subscribe(
          async (details: any) => {
            let data = await details.data();
            /*await data.userref.get().then(function (doc) {
          data.userref = doc.data();
        });*/
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  //get news
  public getnews(group): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection('News', (ref) =>
          ref.where('group', '==', group).orderBy('orderby', 'desc')
        )
        .valueChanges()
        .subscribe(
          (news: any) => {
            resolve(news);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  // news details
  public getnewsdetail(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection('News')
        .doc(id)
        .valueChanges()
        .subscribe(
          (news: any) => {
            resolve(news);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  // get  project Interests
  public getprojectInterests(projectid): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      this.adb
        .collection('Interests', (ref) =>
          ref.where('project', '==', projectid).orderBy('date', 'desc')
        )
        .get()
        .subscribe(
          async (project: any) => {
            let data = await project.docs.map((element) => {
              let item = element.data();
              this.adb
                .doc(`Employees/${item.worker}`)
                .get()
                .subscribe((doc) => {
                  item.workerref = doc.data();
                });
              item.userref.get().then(function (doc) {
                item.userref = doc.data();
              });
              return item;
            });
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  //reject offer
  public rejectoffer(docid) {
    return this.adb.collection('Interests').doc(docid).delete();
  }

  //accept offer
  public acceptoffer(docid, details): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      console.log('details', details, 'docid', docid);
      this.adb
        .collection('Interests')
        .doc(docid)
        .update(details)
        .then((data) => {
          console.log('data', data);
          resolve(data);
        })
        .catch((error) => {
          console.log('error', error);
          reject(error);
        });
    });
  }

  //begin project
  public beginproject(docid, details): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection('Interests')
        .doc(docid)
        .update(details)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  //submitreview
  public submitreview(docid, details): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection('Reviws')
        .doc(docid)
        .set(details)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  // get companies reviews
  public getcompanyreviews(companyid): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection('Reviws', (ref) =>
          ref.where('companyid', '==', companyid).orderBy('date', 'desc')
        )
        .get()
        .subscribe(
          async (company: any) => {
            let data = await company.docs.map((element) => {
              let item = element.data();
              item.userref.get().then(function (doc) {
                item.userref = doc.data();
              });
              return item;
            });
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  //update rating in compant
  updatecompanyrating(id, data) {
    this.adb.collection('Company').doc(id).update(data);
  }

  //Uplaod To FireStoreage
  downloadableURL = '';
  task: AngularFireUploadTask;
  async uplaodfile(basePath, file) {
    if (file) {
      const filePath = `${basePath}/` + Date.now(); // path at which image will be stored in the firebase storage
      this.task = this.storeage.upload(filePath, file); // upload task

      // this.progress = this.snapTask.percentageChanges();
      return (await this.task).ref.getDownloadURL();
    } else {
      alert('No images selected');
      return (this.downloadableURL = '');
    }
  }
}
