import { Component } from '@angular/core';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import {
  ActionSheetController,
  AlertController,
  MenuController,
  ModalController,
  Platform,
} from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { LanguageserviceService } from './services/language/languageservice.service';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { UtilService } from './services/util/util.service';
import { Router } from '@angular/router';
import { ApiService } from './services/firebase/api.service';
import { SharingPage } from './sharing/sharing.page';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  colorTheme = 'light';
  User;
  constructor(
    private platform: Platform,
    private langservice: LanguageserviceService,
    private oneSignal: OneSignal,
    private statusBar: StatusBar,
    private modalCtrl: ModalController,
    private api: ApiService,
    private router: Router,
    private alertController: AlertController,
    private util: UtilService,
    private menu: MenuController,
    public actionSheetController: ActionSheetController,
    private translateService: TranslateService
  ) {
   
    this.initializeApp();
    let colorTheme = localStorage.getItem('colorTheme');
    if (colorTheme) {
      this.handleThemeClick(colorTheme);
    }
  }

  handleThemeClick(theme) {
    document.body.setAttribute('color-theme', theme);
    localStorage.setItem('colorTheme', theme);
    this.colorTheme = theme;
    console.log('theme', this.colorTheme);
  }

  initializeApp() {
    this.platform.ready().then(async (val) => {
      await this.translateService.setDefaultLang("no");
      let uid = localStorage.getItem('Uid');
      if (uid) this.getUserProfile(uid);

      this.langservice.setInitialAppLanguage();
      this.statusBar.backgroundColorByHexString('#F15D4A');

      setTimeout(async () => {
        await this.oneSignal.startInit(
          environment.onesignal.User.appId,
          environment.onesignal.User.googleProjectNumber
        );
        this.oneSignal.getIds().then((data) => {
          console.log('iddddd', data);
          localStorage.setItem('userfcm', data.userId);
        });
        await this.oneSignal.endInit();
      }, 500);
    });
  }

  navigateAppContent(appcontent) {
    this.router.navigate(['aboutus'], { queryParams: { content: appcontent } });
  }

  getUserProfile(uid) {
    this.api.getUserProfile(uid).subscribe((data) => {
      this.User = data;
    }),
      (error) => {
        console.error(error);
        this.util.showToast(
          this.translateService.instant('Start.PleaseTryToLoginAgain'),
          'danger',
          'bottom'
        );
        this.api.signOut();
      };
  }

  async shareWith() {
    const modal = await this.modalCtrl.create({
      component: SharingPage,
      cssClass: 'SharingPage',
    });
    return await modal.present();
  }

  async logoutAlertConfirm() {
    const alert = await this.alertController.create({
      header: this.translateService.instant('Start.Confirm'),
      message: this.translateService.instant('Start.Confirm'),
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          },
        },
        {
          text: 'Yes',
          handler: () => {
            console.log('Confirm Okay');
            this.api
              .signOut()
              .then((data) => {
                this.router.navigateByUrl('/login', { replaceUrl: true });

                this.menu.close();
              })
              .catch((error) => {
                this.util.showToast(
                  this.translateService.instant('Start.ErrorWhileLogout'),
                  'danger',
                  'bottom'
                );
              });
          },
        },
      ],
    });

    await alert.present();
  }
}
